import { PieceType } from 'common/types'
import { Pencil } from 'lucide-react'
import { DateTime } from 'luxon'
import React, { useContext, useMemo, useState } from 'react'
import { cn } from 'shared/utils/web/cn'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { Text, Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table'
import { AddOrderDialog } from './AddOrderDialog'
import { EditLabelDialog } from './EditLabelDialog'

const PIECE_TYPES: PieceType[] = [
  'CABLE',
  'CASE',
  'COMPUTER',
  'MICROPHONE',
  'POWER',
  'SDCARD',
]

export const Orders: React.FC = () => {
  const { orders, ordersUsage } = useContext(DataContext)

  const sortedOrderIds = useMemo(
    () =>
      Object.keys(orders).sort(
        (idA, idB) => orders[idB].timestamp - orders[idA].timestamp,
      ),
    [orders],
  )

  const [addCommandDialogData, setAddCommandDialogData] = useState<{
    deferred: Deferred<void>
  } | null>(null)

  const [editLabelDialogData, setEditLabelDialogData] = useState<{
    deferred: Deferred<string>
    label: string
    orderId: string
  } | null>(null)

  const handleOrderEdit = async (orderId: string, label: string) => {
    const deferred = new Deferred<string>()
    setEditLabelDialogData({ deferred, label, orderId })
    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditLabelDialogData(null)
    }
  }

  const handleAddCommand = async () => {
    const deferred = new Deferred<void>()
    setAddCommandDialogData({
      deferred,
    })
    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setAddCommandDialogData(null)
    }
  }

  return (
    <>
      {addCommandDialogData && <AddOrderDialog {...addCommandDialogData} />}
      {editLabelDialogData && <EditLabelDialog {...editLabelDialogData} />}

      <div className="flex flex-1 flex-col gap-3 overflow-y-auto p-3">
        <div className="flex items-center justify-between">
          <Title>Commandes</Title>
          <Button onClick={handleAddCommand}>Ajouter une commande</Button>
        </div>
        <div className="flex flex-1 overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead rowSpan={2}>Libellé</TableHead>
                <TableHead rowSpan={2}>Date</TableHead>
                {PIECE_TYPES.map((pieceType) => (
                  <TableHead
                    className="text-center"
                    key={pieceType}
                    colSpan={3}
                  >
                    {pieceType}
                  </TableHead>
                ))}
              </TableRow>
              <TableRow>
                {PIECE_TYPES.map((pieceType) => (
                  <React.Fragment key={pieceType}>
                    <TableHead>#</TableHead>
                    <TableHead>
                      <Text variant="success">OK</Text>
                    </TableHead>
                    <TableHead>
                      <Text variant="alert">KO</Text>
                    </TableHead>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedOrderIds.map((orderId) => {
                const order = orders[orderId]
                const { timestamp, label, partsCounts } = order
                return (
                  <TableRow key={orderId}>
                    <TableCell>
                      {label}
                      <span
                        className="ml-4 inline-block cursor-pointer"
                        onClick={() => handleOrderEdit(orderId, label)}
                      >
                        <Pencil size={16} />
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {DateTime.fromMillis(timestamp)
                        .setLocale('fr')
                        .toLocaleString(DateTime.DATE_MED)}
                    </TableCell>
                    {PIECE_TYPES.map((pieceType, index) => (
                      <React.Fragment key={pieceType}>
                        <TableCell
                          className={cn(
                            'text-right',
                            index % 2 && 'bg-popover',
                          )}
                        >
                          {partsCounts[pieceType] -
                            (ordersUsage[orderId]?.[pieceType]?.ok ?? 0) -
                            (ordersUsage[orderId]?.[pieceType]?.ko ?? 0) || ''}
                        </TableCell>
                        <TableCell
                          className={cn(
                            'text-right',
                            index % 2 && 'bg-popover',
                          )}
                        >
                          <Text variant="success">
                            {ordersUsage[orderId]?.[pieceType]?.ok || ''}
                          </Text>
                        </TableCell>
                        <TableCell
                          className={cn(
                            'text-right',
                            index % 2 && 'bg-popover',
                          )}
                        >
                          <Text variant="alert">
                            {ordersUsage[orderId]?.[pieceType]?.ko || ''}
                          </Text>
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  )
}
