import { PartReplacement } from 'common/types'
import { useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { DataContext } from '../../DataProvider'
import { repairDevice } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Select } from '../../components/Select'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import { formCurrentDateTime } from '../../utils/formCurrentDateTime'
import { getCeiledToMinuteISO } from '../../utils/getCeiledToMinuteISO'
import {
  OrderSelector,
  computeOrdersAvailableStock,
} from '../orders/OrderSelector'
import { EditDeviceDialogData } from './EditDeviceDialog'

export const RepairDeviceDialog = ({
  device,
  deferred,
  minDatetime,
  serial,
}: EditDeviceDialogData) => {
  const { source, orders, ordersUsage } = useContext(DataContext)

  const ordersStock = useMemo(
    () => computeOrdersAvailableStock(orders, ordersUsage),
    [orders, ordersUsage],
  )

  const { handleSubmit, register, formState, watch } = useForm<
    PartReplacement & { datetime: string }
  >({
    defaultValues: {
      datetime: formCurrentDateTime(),
      partType: 'MICROPHONE',
      orderId: '',
      comment: '',
    },
  })

  const partType = watch('partType')

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog
      title={<Title>Remplacer une pièce défectueuse</Title>}
      onClose={handleClose}
    >
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async ({ datetime, ...values }) => {
          await repairDevice(
            serial,
            device,
            values,
            source,
            new Date(datetime).valueOf(),
          )
          deferred.resolve({})
        })}
      >
        <div>🚨 À n'utiliser que lorsque la pièce remplacée est HS</div>
        <div className="flex gap-3">
          <div className="flex flex-col gap-2">
            <label>Type de pièce</label>
            <Select {...register('partType')}>
              <option value="MICROPHONE">MICROPHONE</option>
              <option value="SDCARD">SDCARD</option>
              <option value="CASE">CASE</option>
              <option value="CABLE">CABLE</option>
              <option value="POWER">POWER</option>
            </Select>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <label>Commande</label>
            <OrderSelector
              type={partType}
              registration={register('orderId', {
                required: 'Commande requise',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.orderId && (
              <Error>{formState.errors.orderId.message}</Error>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label>Commentaire</label>
          <Textarea
            {...register('comment', {
              required: 'Commentaire requis',
            })}
          />
          {formState.errors.comment && (
            <Error>{formState.errors.comment.message}</Error>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label>Date</label>
          <Input
            type="datetime-local"
            min={minDatetime ? getCeiledToMinuteISO(minDatetime) : undefined}
            {...register(
              'datetime',
              minDatetime
                ? {
                    min: {
                      value: getCeiledToMinuteISO(minDatetime),
                      message: 'Date invalide',
                    },
                  }
                : undefined,
            )}
          />
          {formState.errors.datetime && (
            <Error>{formState.errors.datetime.message}</Error>
          )}
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
