import { DeviceEdition } from 'common/types'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Device } from 'shared/types/fleet'
import { DEVICE_STATUS, getDeviceStatusDisplay } from 'shared/utils/device'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { editDevice } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { FacilitySelector } from '../../components/FacilitySelector'
import { Select } from '../../components/Select'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import { Edited } from '../../utils/computeDiff'
import { formCurrentDateTime } from '../../utils/formCurrentDateTime'
import { getCeiledToMinuteISO } from '../../utils/getCeiledToMinuteISO'
import { getRoomValidator } from '../../utils/roomValidation'

export type EditDeviceDialogData = {
  device: Device
  deferred: Deferred<Edited<DeviceEdition>>
  minDatetime: DateTime | null
  serial: string
}

export const EditDeviceDialog = ({
  device,
  deferred,
  minDatetime,
  serial,
}: EditDeviceDialogData) => {
  const { source, facilities, facilityDevices } = useContext(DataContext)

  const { handleSubmit, register, formState } = useForm<
    DeviceEdition & { datetime: string }
  >({
    defaultValues: {
      datetime: formCurrentDateTime(),
      room: device.room,
      facilityId: device.facilityId,
      status: device.status,
      comment: '',
    },
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog title={<Title>Éditer l'appareil</Title>} onClose={handleClose}>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async ({ datetime, ...values }) => {
          const diff = await editDevice(
            serial,
            device,
            values,
            source,
            new Date(datetime).valueOf(),
          )
          deferred.resolve(diff)
        })}
      >
        <div className="flex flex-col gap-2">
          <label>Statut</label>
          <div className="flex">
            <Select {...register('status')}>
              {DEVICE_STATUS.map((status) => (
                <option key={status} value={status}>
                  {getDeviceStatusDisplay(status)}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label>Établissement</label>
          <FacilitySelector
            facilities={facilities}
            registration={register('facilityId', {
              required: 'Établissement requis',
            })}
          />
          {formState.errors.facilityId && (
            <Error>{formState.errors.facilityId.message}</Error>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <label>Chambre</label>
          <Input
            {...register('room', {
              validate: getRoomValidator(facilityDevices, serial),
            })}
          />
          {formState.errors.room && (
            <Error>{formState.errors.room.message}</Error>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <label>Commentaire</label>
          <Textarea {...register('comment')} />
        </div>

        <div className="flex flex-col gap-2">
          <label>
            Date <b>exacte</b> de la modification
          </label>
          <Input
            type="datetime-local"
            min={minDatetime ? getCeiledToMinuteISO(minDatetime) : undefined}
            {...register(
              'datetime',
              minDatetime
                ? {
                    min: {
                      value: getCeiledToMinuteISO(minDatetime),
                      message: 'Date invalide',
                    },
                  }
                : undefined,
            )}
          />
          {formState.errors.datetime && (
            <Error>{formState.errors.datetime.message}</Error>
          )}
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
